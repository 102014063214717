import React, {Component} from "react";
import axios from "../../api";
import {Button, Menu as DropdownMenu, message, Popover, Statistic, Tabs} from "antd";
import {connect} from "react-redux";
import {selectProject} from "../../actions/actions";
import {MinMaxModal} from "./MinMaxModal";
import {
    checkAllPermissions,
    checkAnyOfPermissions,
    createScopeLink,
    downloadExcel,
    getProjectId,
    openLinkInNewTab
} from "../../helpers";
import * as Auth from '../../AuthService'
import "../helpers.css"
import {MaterialsSearch} from "../../pages/MaterialsSearch";
import {StandardTable} from "../helpers/TablePanel";
import {ActionsMenu} from "../helpers/ActionsMenu";
import {HelpModal} from "../helpers/modals/HelpModal";
import {InfoModal} from "../helpers/modals/InfoModal";
import {ScenarioLink} from "../helpers/cards/MaterialBlock";
import api from "../../api";

const { TabPane } = Tabs;

export class MinMaxIterationPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            iterations: [],
            openModal: false,
            openInfoModal: false,
            scopes: [],
            iteration: {
                constants: {

                }
            },
            hasRights: false,
            loading: false,
            newPagination: {
                current: 1,
                pageSize: 10
            },
            total: 0,
            _filters: {}
        }
    }

    calcTypeLabels = {
        standard: 'Unsupervised',
        recommendation: 'Supervised',
        naive: 'Statistical Forecasting (naive)',
        true: 'Yes',
        false:'No',
        never: 'Never',
        month: 'Every Month',
        quarter: 'Every 3 Months',
        halfYear: 'Every Half a Year',
        year: 'Every Year'
    }

    getIterations(newPagination = this.state.newPagination, _filters = this.state._filters) {
        this.setState({loading: true})
        const project_id = getProjectId(this.props.location)
        const pagination = {
            current: newPagination.current,
            size: newPagination.pageSize
        }

        if (newPagination.pageSize !== this.state.newPagination.pageSize) {
            pagination.current = 1
        }
        const data = {
            pagination,
            _filters

        }
        this.setState({projectId: project_id, })
        axios.post(`/projects/${project_id}/iterations`,  data, Auth.createConfig())
            .then(json => this.setState({iterations: json.data.iterations, projectId: project_id, newPagination: newPagination, _filters: _filters, total: json.data.total}))
            .finally(() => this.setState({loading: false}))
    }

    onChange = (newPagination, _filters, _sorter) => {
        this.getIterations(newPagination, _filters)
    }


    componentDidMount() {
        this.getIterations()
        this.checkAdminPermission()
        let p = window.location.search;
        const params = new URLSearchParams(p);
        const from = params.get('search')
        if (from) {
            this.setState({activeKey: "2"})
        }
        const project_id = getProjectId(this.props.location)
        api.get(`/projects/${project_id}/iterations/scopes`).then(json => this.setState({scopes: json.data.items}))

    }

    checkAdminPermission = () => {
        checkAnyOfPermissions(['senior-user', 'approve-user', 'insurance-admin', 'admin', 'superadmin', 'material-analyst', 'inventory-analyst', 'dm-maintenance', 'dm-inventory', 'stock-owner'])
            .then(() => this.setState({hasRights: true}))
            .catch(() => this.setState({hasRights: false}))
    }

    createActionsMenu(iteration) {
        const project_id = getProjectId(this.props.location)
        return <DropdownMenu>
            <DropdownMenu.Item>
                <span className='url-link' onClick={() => openLinkInNewTab(`/projects/${project_id}/min-max/scenarios/${iteration._id}/overview`)}>Open</span>
            </DropdownMenu.Item>
            {this.state.hasRights && <DropdownMenu.Item>
                <span className='url-link' onClick={() => this.rerunIteration(iteration._id)}>Re-run iteration</span>
            </DropdownMenu.Item>}
            <DropdownMenu.Item>
                <span className='url-link' onClick={() => this.exportIteration(iteration._id, iteration.iteration_name)}>Export</span>
            </DropdownMenu.Item>
            {(this.state.hasRights && iteration.status === 'scenarioProcessing') && <DropdownMenu.Item>
                <span className='url-link' onClick={() => this.stopIteration(iteration._id)}>Stop</span>
            </DropdownMenu.Item>}
            {this.state.hasRights && <DropdownMenu.Item>
                <span className='url-link' onClick={() => this.deleteIteration(iteration._id)}>Delete</span>
            </DropdownMenu.Item>}
        </DropdownMenu>
    }


    getProgress = (item) => {
        const valueStyle= {fontSize: 16}
        if (Object.keys(item).includes('processed')) {
            if (item.processed && item.total) {
                return <Statistic valueStyle={valueStyle} value={item.processed} suffix={`/ ${item.total}`}/>

            }
        }
        return <Statistic className='text-center' valueStyle={valueStyle} value={item.results && item.results.items_affected || 0}/>
    } 

    getColumns = () => {
        const valueStyle= {fontSize: 16}
        return [
            {
                title: 'Scenario Name',
                dataIndex: 'iteration_name',
                key: 'iteration_name',
                align: 'left',
                render: (name, scenario) =>  <ScenarioLink name={name} id={scenario._id} />

            },
            {
                title: 'Calculations Type',
                dataIndex: 'calculations_type',
                key: 'scenario_type',
                align: 'left',
                render: (calculations_type) =>  this.calcTypeLabels[calculations_type] || this.calcTypeLabels['standard']

            },
            {
                title: 'Scenario Scope',
                dataIndex: 'scope',
                key: 'scope',
                filters: this.state.scopes.map(v=> {
                    return {
                        value: v.name || v.label,
                        text: v.name || v.label
                    }
                }),
                render: scope => <div className={'beautiful-link'} onClick={() => {
                    const project_id = getProjectId(this.props.location)
                    window.open(createScopeLink(project_id, scope._id))
                }}>{scope && (scope.name || scope.label)}</div>
            },
            {
                title: 'Materials Analyzed',
                dataIndex: 'analyzed',
                key: 'analyzed',
                align: 'center',
                render: (name,  item) =>  this.getProgress(item)
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                render: (status, row) =>  <Popover content={row['results']['error']}>
                            <div className={status}>{this.statusLabels[status]}</div>
                        </Popover>
            },
            {
                title: 'Started',
                dataIndex: 'start',
                key: 'start',
                align: 'center',
                render: (start) =>  <div>{this.workWithDate(start)}</div>
            },
            {
                title: 'Duration',
                dataIndex: 'finished',
                key: 'finished',
                align: 'center',
                render: (finished, item) =>  <div>{this.getDuration(item['start'], finished)}</div>
            },
            {
                title: 'Used ML Recommendations',
                dataIndex: 'ml',
                key: 'ml',
                align: 'center',
                render: (ml, item) =>  <div>{item['constants']['ml'] ? 'Yes' : 'No'}</div>
            },
            {
                title: 'Params',
                dataIndex: 'info',
                key: 'info',
                width: 100,
                align: 'center',
                render: (name, item) => <p style={{paddingTop: 5}}>{item.calculations_type === 'standard' && <Button
                    onClick={() => this.setState({openInfoModal: true, iteration: item, padding: 5},)}>?</Button>}</p>
            },
            {
                title: 'Actions',
                dataIndex: 'actions',
                key: 'actions',
                align: 'center',
                width: 100,
                render: (name, item) => <ActionsMenu actions={this.createActionsMenu(item)}/>
            }
        ];
    }

    workWithDate = dateStr => {
        if (!dateStr) {
            return ''
        } else {
            const options = {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            };
            return new Date(dateStr).toLocaleDateString(undefined, options) + ' ' + new Date(dateStr).toLocaleTimeString()
        }
    }

    getDuration = (startDateStr, finishDateStr) => {

        if (!finishDateStr) {
            return ''
        }

        const getRemains = (_diff, base) => {
            return _diff % base
        }

        let startDate = new Date(startDateStr)
        let finishDate = new Date(finishDateStr)

        let diff = Math.abs(finishDate - startDate) / 1000
        if (diff < 60) {
            return `${getRemains(diff, 60)} second(s).` ;
        } else if (diff < 3600) {
            return `${Math.floor(diff / 60)} minute(s) ${getRemains(diff, 60)} second(s).` ;
        } else if (diff < 86400) {
            return  `${Math.floor(diff / (60 * 60))} hour(s) ${Math.floor(getRemains(diff / 60, 60))} minute(s) ${getRemains(diff, 60)} second(s).` ;
        } else {
            return `${Math.floor(diff / (60 * 60 * 24))} day(s) ${Math.floor(getRemains(diff / (60 * 60), 24))} hour(s) ${Math.floor(getRemains(diff / 60, 60))} minute(s) ${getRemains(diff, 60)} second(s).` ;
        }
    }

    deleteIteration(iterationId) {
        const project_id = getProjectId(this.props.location)
        axios.get(`/projects/${project_id}/iterations/${iterationId}/delete`, Auth.createConfig()).then(json => {
            alert(json.data)
            this.getIterations()
        })

    }

    stopIteration(iterationId) {
        const project_id = getProjectId(this.props.location)
        axios.get(`/projects/${project_id}/iteration/${iterationId}/stop`, Auth.createConfig()).then(json => {
            message.info('Trying to stop Iteration')
            this.getIterations()
        })

    }

    rerunIteration(iterationId) {
        const project_id = getProjectId(this.props.location)
        message.info('Start re-calculating the scenario')
        axios.get(`/projects/${project_id}/iteration/${iterationId}/run`, Auth.createConfig())
            .then(() => {
                this.getIterations()
            }).catch(() => {
            message.error('Something went wrong! Please try again later')
        })

    }

    exportIteration = (iteration_id, iteration_name) => {
        message.info('Starting the export of ' + iteration_name)
        const project_id = this.props.location.pathname.split('/')[2]
        const config = Auth.createConfig();
        config['responseType'] = 'blob';
        axios.get(`/projects/${project_id}/iterations/${iteration_id}/min-max/export`, config)
            .then(json => {
                downloadExcel(json.data, `Proposed MIN-MAX ${iteration_name}.xlsx`)
            }).catch(error => {
            console.log(error)
        })
    }

    parseDataSource = () => {
        return this.state.iterations.map(iteration => {
            return {
                key: iteration._id,
                _id: iteration._id,
                scope: iteration.scope,
                calculations_type: iteration.calculations_type,
                iteration_name: iteration.iteration_name,
                constants: iteration.constants,
                results: iteration.results,
                start: iteration.results.start,
                finished: iteration.results.finished,
                status: iteration.results.status,
                processed: iteration.processed,
                total: iteration.total
            }
        })
    }

    statusLabels =  {
        scenarioFinished: 'Finished', scenarioProcessing: 'Performing the calculations', scenarioError: 'Error while performing calculations', scenarioStopped: 'Stopped by user'
    }

    render() {

        const project_id = getProjectId(this.props.location)

        const setActiveKey = key => {
            this.setState({activeKey: key})
        }

        return (
            <div style={{padding: 24}}>
                <Tabs defaultActiveKey={"1"} activeKey={this.state.activeKey} onTabClick={setActiveKey}>
                    <TabPane tab={'Scenarios'} key={"1"}>
                        <Button className='float-right' style={{marginLeft: 10}} onClick={() => this.getIterations()}>Update Statuses</Button>
                <Button className='float-right' style={{marginLeft: 10}} onClick={() => this.setState({helpModal: true})}>?</Button>
                        {this.state.hasRights && <Button className='float-right' onClick={() => this.setState({openModal: true})}>Add New
                            Scenario</Button>}
                <StandardTable data={this.parseDataSource()}
                               scroll={800}
                               loading={this.state.loading}
                               total={this.state.total}
                               onChange={this.onChange}
                               pageSize={this.state.newPagination.pageSize}
                               currentPage={this.state.newPagination.current}
                               tableProps={{}}
                               tableSize={'small'}

                       columns={this.getColumns()} />
                <MinMaxModal modalState={this.state.openModal}
                             scopes={this.state.scopes}
                             calcTypeLabels={this.calcTypeLabels}
                             projectId={this.state.projectId}
                             runCalcs={this.rerunIteration}
                             closeModal={(_id) => {
                                 this.setState({openModal: false})
                                 this.getIterations()
                             }}/>
                <HelpModal isModalVisible={this.state.helpModal} setModalVisible={(value) => this.setState({helpModal: value})} />
                <InfoModal isModalVisible={this.state.openInfoModal} iteration={this.state.iteration} setModalVisible={(value) => this.setState({openInfoModal: value})} />
                    </TabPane>
                    {this.state.hasRights && <TabPane tab={'Materials'} key={"2"}>
                        <MaterialsSearch projectId={project_id}/>
                    </TabPane>}
                </Tabs>
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        inventoryInfo: state.inventoryInfo,
        selectedProject: state.selectedProject
    }
}

export default connect(mapStateToProps, {
    selectProject
})(MinMaxIterationPanel)
