import React, {useState} from 'react';
import {getProjectId} from "../../helpers";
import {DataCard} from "../helpers/cards/Card";
import api from "../../api";
import {Button, Descriptions, Select, Spin, Statistic} from "antd";

export function MovementTypeSettings(props) {

    const projectId = getProjectId(props.location);
    const [possibleYears, setPossibleYears] = React.useState([]);
    const [selectedYears, setSelectedYears] = React.useState(['2022', '2021', '2020']);

    const [possibleMVTypes, setPossibleMVTypes] = React.useState([]);
    const [selectedMVTypes, setSelectedMVTypes] = React.useState(['261']);

    const [results, setResults] = React.useState({});
    const [isGettingTheResults, setIsGettingTheResults] = useState(false);
    const [loading, setLoading] = useState(false);

    const getPossibleYears = () => {
        api.get(`/projects/${projectId}/transactions/years`)
            .then((response) => {
                setPossibleYears(response.data)
            })
    }

    const getPossibleMVTypes = () => {
        api.get(`/projects/${projectId}/transactions/mv-types`)
            .then((response) => {
                setPossibleMVTypes(response.data)
            })
    }

    const runCategories = () => {
        setLoading(true)
        setIsGettingTheResults(true)
        const data = {
            years: selectedYears,
            mv_types: selectedMVTypes
        }
        api.post(`/projects/${projectId}/movement-type`, data)
            .then(json => setResults(json.data))
            .finally(() => setLoading(false))
    }

    React.useEffect(() => {
        getPossibleYears()
        getPossibleMVTypes()
    }, [projectId])

    const handleChangeYears = (value) => {
        value.sort()
        setSelectedYears(value)
    }

    const handleChangeMVTypes = (value) => {
        value.sort()
        setSelectedMVTypes(value)
    }

    return <div style={{padding: '10px'}}>
        <DataCard title={'Movement Type Recalculations'}>
            Use next years: <Select
            mode="tags"
            style={{
                width: '100%',
            }}
            placeholder="Years"
            onChange={handleChangeYears}
            options={possibleYears}
            value={selectedYears}
        />

            Use GI movement types: <Select
            mode="tags"
            style={{
                width: '100%',
            }}
            placeholder="Movement types"
            onChange={handleChangeMVTypes}
            options={possibleMVTypes}
            value={selectedMVTypes}
        />

            <DataCard style={{margin: '10px 0'}} title={'Note'}>
                <div>
                    <b>High Turnover:</b> {'>='} 3 goods issue movements ({selectedMVTypes.join(', ')}) per annum in
                    past years ({selectedYears.join(', ')}) but not <b>Insurance Spare</b>
                </div>
                <div>
                    <b>Low Turnover:</b> {'>'} 1 goods issue movements ({selectedMVTypes.join(', ')}) in past years
                    ({selectedYears.join(', ')}) but not <b>High Turnover</b>
                </div>
                <div>
                    <b>Slow-Moving:</b> {'<='} 1 goods issue movements ({selectedMVTypes.join(', ')}) in past years
                    ({selectedYears.join(', ')})
                </div>
                <div>
                    <b>Non-Moving:</b> 0 goods issue movements ({selectedMVTypes.join(', ')}) in past years
                    ({selectedYears.join(', ')})
                </div>
            </DataCard>


            {isGettingTheResults && <Spin spinning={loading}><DataCard style={{margin: '10px 0'}} title={'Results'}>
                <Descriptions bordered size={'small'}>
                    {Object.keys(results).map(v => <Descriptions.Item span={3}
                                                                      label={v}><Statistic value={results[v]['count']}
                                                                                           valueStyle={{fontSize: 12}}/></Descriptions.Item>)}

                </Descriptions>
            </DataCard></Spin>}

            <div>
                <Button type="primary" onClick={runCategories} style={{float: 'right', marginTop: 10}}>Re-run</Button>
            </div>


        </DataCard>
    </div>

}
