import React, {Component, useEffect, useState} from "react";
import axios from "../../api";
import {checkAnyOfPermissions, getProjectId} from "../../helpers";
import * as Auth from '../../AuthService'
import {Button, Card, Descriptions, message} from "antd";
import {Line} from 'react-chartjs-2';
import './settings.css'
import {CustomTabs} from "../helpers/CustomTabs";
import {EditOutlined} from "@ant-design/icons";
import {useForm} from "antd/es/form/Form";
import {StandardForm} from "../helpers/forms/StandardForm";
import api from "../../api";


function GlobalSettings({projectId, constants}) {

    const [project, setProject] = useState({})
    const [globalSettings, setGlobalSettings] = useState({})
    const [values, setValues] = useState({})
    const [activeKey, setActiveKey] = useState('all')

    const [canEdit, setCanEdit] = useState(false)


    useEffect(() => {
        getProjectData()
        getGlobalValues()
        checkAnyOfPermissions(['senior-user', 'approve-user', 'insurance-admin', "admin", 'material-analyst'])
            .then(() => setCanEdit(true))
    }, [projectId]);

    const getProjectData = () => {
        api.get(`/projects/${projectId}/info`).then(json => setProject(json.data))

    }
    const createSLAttributes = (attributes) => {
        return attributes && attributes.map(a => {
            return {
                value: a['sl'], label: `Service Level: ${a.sl}%, CSF -  ${a.csf}`
            }
        });
    }

    const getGlobalValues = () => {
        api.get(`/projects/${projectId}/global-settings`)
            .then(json => {
                const _data = json.data.constants
                for (let key of Object.keys(_data)) {
                    if (key === 'holding') {
                        _data['holding'] = _data['holding'] * 100
                    }
                }
                setGlobalSettings(_data)
            })
            .catch(e => setGlobalSettings({}))
    }

    const getServiceFactorForm = (label, key, attributesList, attributesKey) => [{
        label: label,
        name: key,
        _type: 'select-object',
        message: 'Error',
        required: true,
        options: createSLAttributes(attributesList)
    },]

    const getStandardForm = (label, key, field_type) => [{
        label: label,
        name: key,
        _type: field_type,
        message: 'Error',
        required: true,
    },]

    const currency_settings = [{label: 'Currency', key: 'currency', editable: canEdit, field_type: 'string'}, {
        label: 'Alternative Currency', key: 'alt_currency', editable: canEdit, field_type: 'string'
    }, {label: 'Currency Rate', key: 'currency_rate', editable: canEdit, field_type: 'string'}, {
        label: 'Default Price', key: 'default_price', editable: canEdit,field_type: 'number'
    },]

    const settings_span2 = [{label: 'Service Factor Target (%)', key: 'service_target', editable: canEdit, field_type: 'string'}, {
        label: 'Holding Cost (%)', key: 'holding', editable: canEdit, field_type: 'number'
    }, {label: 'Storage Cost - Default', key: '1', editable: canEdit, field_type: 'string'}, {
        label: 'Order Cost', key: '2', editable: canEdit, field_type: 'string'
    }, {
        label: 'Average Stock Formula', key: '3', editable: canEdit, field_type: 'string'
    }, {
        label: 'Average Holding Cost Savings Target (%)', key: '4', editable: canEdit, field_type: 'string'}]

    const settings_service_factor = [{
        label: 'Service Level (Global)', key: 'service_level_global', editable: canEdit,
    }, {label: 'Service Level (A item)', key: 'service_level_a', editable: canEdit}, {
        label: 'Service Level (B item)', key: 'service_level_b', editable: canEdit
    }, {label: 'Service Level (C item)', key: 'service_level_c', editable: canEdit},]


    const handleSave = (value) => {
        const _globalSettings = {...globalSettings, ...value}
        console.log(_globalSettings)
        for (let key of Object.keys(_globalSettings)) {
            if (key === 'holding') {
                _globalSettings['holding'] = _globalSettings['holding'] / 100
            }
        }
        console.log(_globalSettings)
        api.post(`/projects/${projectId}/global-settings/save`, _globalSettings).then(() => {
            message.success("Global Settings were updated successfully!")
            getGlobalValues();
        })
    }

    const createStandardFormFields = (list) => {
        return list.map(item => {
            return {
                ...item,
                formFields: item.editable ? getStandardForm(item.label, item.key, item.field_type) : null
            }
        })
    }

    const createServiceFactorFormFields = (list) => {
        return list.map(item => {
            return {
                ...item,
                formFields: getServiceFactorForm(item.label, item.key, constants.service_factor_table, 'sl')
            }
        })
    }

    const getSettingsTabs = () => [{
        name: 'All Settings', key: 'all', children: <div style={{marginTop: 10}}>
            <CustomDescriptions span={3} handleSave={handleSave}
                                values={globalSettings}
                                data={createStandardFormFields(settings_span2)}/>
        </div>
    }, {
        name: 'Currency Settings', key: 'currency', children: <div>
            <CustomDescriptions span={3} handleSave={handleSave}
                                data={createStandardFormFields(currency_settings)}
                                values={globalSettings}/>
        </div>
    }, {
        name: 'Service Factor Settings', key: 'service_factor', children: <div>
            <ServiceFactorGraph constants={constants}/>
            <CustomDescriptions span={3} data={createServiceFactorFormFields(settings_service_factor)}
                                values={globalSettings} handleSave={handleSave}/>
        </div>
    }]

    return (<>
        <div style={{fontSize: 20, marginLeft: '35%', marginBottom: 10}}>Project: <b>{project.project_name}</b> - Global Settings</div>
        <CustomTabs activeKey={activeKey}
                    defaultKey={activeKey}
                    tabsData={getSettingsTabs()}
                    onChange={_activeKey => setActiveKey(_activeKey)}/>
    </>)
}


export function CustomDescriptions({data, values, span, handleSave, width='30%'}) {

    return  <Card style={{marginTop: 10}} className="card-settings"><Descriptions  size={'small'} bordered>
        {data.map((v, i) => <Descriptions.Item key={i} labelStyle={{width: width}} span={span} label={v.label}>
            {v.editable ? <EditableDescriptionCell handleSave={handleSave}
                                                   value={values[v.key]}
                                                   field={v.key}
                                                   formFields={v.formFields}/> : values[v.key] || ''}
        </Descriptions.Item>)}
    </Descriptions></Card>

}

function EditableDescriptionCell({value, field, formFields, handleSave}) {

    const [isEditMode, setIsEditMode] = useState(false)
    const [form] = useForm()

    const onFinish = (values) => {
        handleSave(values)
        form.resetFields([field])
        setIsEditMode(false)
    }

    const onCancel = () => {
        form.resetFields([field])
        setIsEditMode(false)
    }

    return <div>

        {isEditMode ? <div>
            <StandardForm form={form} fields={formFields}
                          initialValues={{[field]: value}}
                          onFinish={onFinish}/>
            <div style={{float: 'right'}}>
                <Button style={{marginRight: 10}}
                        onClick={onCancel}
                        type={"cancel"}>Cancel</Button>
                <Button onClick={() => form.submit()}
                        className="adaptive-background-color"
                        type={"submit"}>Save</Button>
            </div>
        </div> : <div>{value}<EditOutlined style={{float: 'right'}} onClick={() => setIsEditMode(true)} className="adaptive-color"/></div>}
    </div>
}


function ServiceFactorGraph({constants}) {

    const getChartData = () => {
        const table = constants.service_factor_table;

        return {
            labels: table.map(v => v['sl']), datasets: [{
                label: 'Customer Service Factor',
                data: table.map(v => v['csf']),
                borderColor: '#00205c',
                backgroundColor: 'rgba(0, 255, 128, 0)',
                fill: false,
            },]
        };
    }

    const options = {
        scales: {
            x: {
                type: 'linear', min: 50, max: 105
            }
        }, interaction: {
            intersect: false, axis: 'x'
        },
    };


    return <>
        <Card className="card-settings">
            <Line height={75} data={getChartData()} options={options}/>
        </Card>
    </>
}

export class MinMaxSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            project: {}, project_id: getProjectId(this.props.location), settings: {
                constants: {
                    service_factor_table: [], default_service_level: {}
                }
            }
        }
    }

    componentDidMount() {
        api.get(`/projects/${this.state.project_id}/info`, Auth.createConfig()).then(json => this.setState({project: json.data}))
        api.get(`/projects/${this.state.project_id}/settings`, Auth.createConfig()).then(json => this.setState({settings: json.data}))
    }


    render() {
        return (<Card className="card-settings" style={{height: 'calc(100% - 10px)', margin: 5}}>
            <GlobalSettings projectId={this.state.project_id} constants={this.state.settings.constants}/>
        </Card>);
    }

}
